<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <CollapseRow
          :label="'Actions'"
          :level="1"
        >
          View
        </CollapseRow>

        <!-- <CollapseGroup v-bind:label="'Actions'" v-bind:level="1">
          <template v-slot:header>
            Activate
          </template>
        </CollapseGroup> -->
        <!--
        <CollapseGroup v-bind:label="'Default'" v-bind:level="1">
          <template v-slot:header>
            <input id="blend" type="checkbox" class="w-3 h-3 mr-1 text-indigo-600 form-checkbox">
          </template>
        </CollapseGroup>

        <CollapseGroup v-bind:label="'Selectable in Viewer'" v-bind:level="1">
          <template v-slot:header>
            <input checked type="checkbox" class="w-3 h-3 mr-1 text-indigo-600 form-checkbox">
          </template>
        </CollapseGroup> -->

        <BlockGroup
          :label="'Properties'"
          :closed="false"
        >
          <CollapseGroup
            :label="'Name'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="name"
                type="text"
                class="flex-auto block h-6 px-2 py-2 text-xs leading-tight text-white bg-gray-600 border-2 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>

          <CollapseGroup
            :label="'Position'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="positionX"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >

              <input
                v-model="positionY"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >

              <input
                v-model="positionZ"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>

          <CollapseGroup
            :label="'Target'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="targetX"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >

              <input
                v-model="targetY"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >

              <input
                v-model="targetZ"
                type="number"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>

          <CollapseGroup
            :label="'FOV'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="fov"
                type="number"
                min="0"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>

          <CollapseGroup
            :label="'Camera Near'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="near"
                type="number"
                min="0"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>

          <CollapseGroup
            :label="'Camera Far'"
            :level="1"
          >
            <template v-slot:header>
              <input
                v-model="far"
                type="number"
                min="0"
                class="flex-auto block w-16 h-6 px-2 py-2 mr-1 text-xs leading-tight text-white bg-gray-600 border-2 border-gray-600 rounded-sm input-spinner-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-white focus:text-gray-800 cursor-col-resize"
              >
            </template>
          </CollapseGroup>
        </BlockGroup>

        <!--
        <BlockGroup v-bind:label="'Controls'" v-bind:closed="false">

          <CollapseGroup v-bind:label="'Activate'" v-bind:level="1">
            <template v-slot:header>
              button
            </template>
          </CollapseGroup>

          <CollapseGroup v-bind:label="'Safe Area Overlay'" v-bind:level="1">
            <template v-slot:header>

              <input type="checkbox" class="w-3 h-3 mr-1 text-indigo-600 form-checkbox">
            </template>
            <div>
              SOME SETTINGS
            </div>
          </CollapseGroup>

          <CollapseGroup v-bind:label="'Show Helpers'" v-bind:level="1">
            <template v-slot:header>
              <input type="checkbox" class="w-3 h-3 mr-1 text-indigo-600 form-checkbox">
            </template>
            <div>
              More SETTINGS
            </div>
          </CollapseGroup>

          <CollapseGroup v-bind:label="'Control Motion'" v-bind:level="1">
            <template v-slot:header>
              <select
                class="form-select block w-full py-0.5 text-2xs leading-6 border-gray-500 text-gray-200 bg-gray-700 focus:outline-none focus:ring-gray focus:border-gray-400 sm:leading-5">
                <option>Orbit</option>
                <option>Fixed</option>
                <option>Static</option>
              </select>
            </template>
          </CollapseGroup>

        </BlockGroup>
 -->

        <BlockGroup
          :label="'Options'"
          :closed="false"
        >
          <!-- <CollapseGroup v-bind:label="'Duplicate'" v-bind:level="1">
            <template v-slot:header>
              Button
            </template>
          </CollapseGroup>

          <CollapseGroup v-bind:label="'Reset'" v-bind:level="1">
            <template v-slot:header>
              Button
            </template>
          </CollapseGroup> -->

          <CollapseGroup
            :label="'Delete'"
            :level="1"
          >
            <template v-slot:header>
              <fa-icon
                icon="trash-can"
                class="mr-1 text-red-500 fa-fw"
              /> Delete
            </template>
          </CollapseGroup>
        </BlockGroup>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './NodeSettingMixin'
import utilsmixin from '@components/editor/utils/utilsmixin.js'

export default {
  name: 'BookmarkBlock',

  mixins: [mixin, utilsmixin],

  computed: {

    name: {
      get () {
        return this.node.name
      },
      set (val) {
        this.$emit('settings-changed')

        // this.gui.updateNode(() => {
        //   this.node.name = val
        // })
      }
    },

    positionX: {
      get () {
        return this.node.data.position.x
      },
      set (val) {
        // this.node.data.position.x = val
        // this.updateView()
      }
    },

    positionY: {
      get () {
        return this.node.data.position.y
      },
      set (val) {
      }
    },

    positionZ: {
      get () {
        return this.node.data.position.z
      },
      set (val) {
      }
    },

    targetX: {
      get () {
        return this.node.data.target.x
      },
      set (val) {}
    },

    targetY: {
      get () {
        return this.node.data.target.y
      },
      set (val) {}
    },

    targetZ: {
      get () {
        return this.node.data.target.z
      },
      set (val) {}
    },

    fov: {
      get () {
        return this.node.data.fov
      },
      set (val) {}
    },
    near: {
      get () {
        return this.node.data.near
      },
      set (val) {}
    },
    far: {
      get () {
        return this.node.data.far
      },
      set (val) {}
    }
  },

  methods: {

    updateView () {
      this.gui.controls.activate(this.node)
    }

  }
}

</script>
